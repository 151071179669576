import { create } from 'zustand';

const useMenuActionStore = create((set) => ({
  // State's
  openMenuAction: false,
  templatesChatList: [],
  // Action's
  setOpenMenuAction: (openMenuAction) => set({ openMenuAction }),
  setTemplatesChatList: (templatesChatList) => set({ templatesChatList }),
}));

export default useMenuActionStore;

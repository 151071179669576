import { UserCircleIcon } from '@heroicons/react/24/solid';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import useThemeStore from 'Theme/store';

const BasicAvatar = ({ svg, onEdit, firstname, lastname, email, size = 6 }) => {
  const { theme } = useThemeStore();
  return (
    <div className="mx-auto flex flex-row justify-between items-center mt-3">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <div className={`size-${size} rounded-full overflow-hidden`}>
            {svg ? (
              <div className="avatar">
                <div className={`size-${size} rounded-full overflow-hidden`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: svg,
                    }}
                  />
                </div>
              </div>
            ) : (
              <UserCircleIcon
                className={`size-${size} transition text-cool-gray`}
              />
            )}
          </div>
          {onEdit && (
            <div
              className="absolute -bottom-2 -right-1 rounded-full bg-nue-blue w-6 h-6 flex items-center justify-center"
              onClick={onEdit}
            >
              <PencilSquareIcon className="cursor-pointer w-4 h-4 text-white bg-nue-blue rounded-full" />
            </div>
          )}
        </div>
        {firstname && lastname && email && (
          <div className="flex flex-col">
            <div
              className={`text-lg font-bold uppercase ${
                theme == 'dark' ? 'dark:text-crystal-bell' : 'text-raisin-black'
              }`}
            >{`${firstname} ${lastname}`}</div>
            <div
              className={`text-sm font-normal ${
                theme == 'dark' ? 'dark:text-crystal-bell' : 'text-raisin-black'
              }`}
            >
              {email}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicAvatar;

function SvgComponent({ fillColor = '#44BFF8', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M5 5H11.5625V11.5625H5V5ZM12.4375 5H19V11.5625H12.4375V5ZM5 12.4375H11.5625V19H5V12.4375ZM12.4375 12.4375H19V19H12.4375V12.4375Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;

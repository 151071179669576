import { SparklesIcon } from '@heroicons/react/24/outline';
import {
  BoltIcon,
  PaperClipIcon,
  RocketLaunchIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import useThemeStore from 'Theme/store';
import Emoji from 'react-emojis';
import { cn } from 'utils/styles';

const TemplateCard = ({
  id,
  category,
  title,
  description,
  userInfo,
  files,
  runs,
  multi_default_models,
  default_model,
  onClick,
  icon,
}) => {
  const { theme } = useThemeStore();
  // Formatter
  const formatter = new Intl.NumberFormat('en-US');

  return (
    <div
      onClick={onClick}
      className={cn(
        'font-figtree flex flex-col gap-3 w-[260px] border rounded-[14px] p-3 my-3 cursor-pointer hover:scale-102 ',
        {
          'bg-white border-cool-gray': theme !== 'dark',
          'bg-night-black border-palladium': theme === 'dark',
        }
      )}
    >
      <div
        className={`flex items-center ${
          theme == 'dark' ? 'text-palladium' : 'text-cool-gray'
        }`}
      >
        <TagIcon className={'h-4 w-4 mr-1'} />
        <h2 className="text-xs leading-4 font-semibold">{category}</h2>
        <div className="flex-1"></div>
        <div className="flex gap-[6px] items-center text-agate-violet">
          {userInfo !== null ? (
            userInfo?.avatar?.svg ? (
              <Tooltip
                placement="top"
                title={`${userInfo.fname ?? ''} ${userInfo.lname ?? ''}`}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <div className="avatar">
                  <div className="w-4 h-4 rounded">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: userInfo.avatar.svg,
                      }}
                    />
                  </div>
                </div>
              </Tooltip>
            ) : (
              <UserIcon className="w-4 h-4 transition rounded-full" />
            )
          ) : (
            <BoltIcon className="w-4 h-4 transition rounded-full text-white bg-nue-blue" />
          )}

          {runs && (
            <div className="flex gap-1 items-center">
              <RocketLaunchIcon className="w-4 h-4" />
              <div className="text-xs font-medium leading-4">
                {runs ? formatter.format(runs) : 0}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xl">
          <Emoji emoji={icon ?? 'high-voltage'} />
        </div>
        <h2
          className={cn('text-sm font-semibold leading-[14px] text-left', {
            'text-raisin-black': theme !== 'dark',
            'text-crystal-bell': theme === 'dark',
          })}
        >
          {title}
        </h2>
      </div>
      <div
        className={cn('text-xs leading-[18px] font-normal line-clamp-2', {
          'text-agate-violet': theme !== 'dark',
          'text-crystal-bell': theme === 'dark',
        })}
      >
        {description}
      </div>
      <div className="flex justify-between">
        {multi_default_models?.length > 1 ? (
          <div className={'flex items-center'}>
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: multi_default_models
                      .map((model) => model.name)
                      .join('<br>'),
                  }}
                />
              }
              arrow
            >
              <p
                className={cn(
                  'flex items-center uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit',
                  {
                    'text-cool-gray group-hover:bg-lavender bg-platinum':
                      theme !== 'dark',
                    'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey':
                      theme === 'dark',
                  }
                )}
              >
                {multi_default_models[0]?.name} +{' '}
                {multi_default_models?.length - 1}
                <SparklesIcon className="w-[10px] h-[10px] ml-1" />
              </p>
            </Tooltip>
          </div>
        ) : multi_default_models?.length == 1 ? (
          <>
            <p
              className={cn(
                'uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit',
                {
                  'text-cool-gray group-hover:bg-lavender bg-platinum':
                    theme !== 'dark',
                  'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey':
                    theme === 'dark',
                }
              )}
            >
              {multi_default_models[0]?.name}
            </p>
          </>
        ) : default_model ? (
          <>
            <p
              className={cn(
                'uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit',
                {
                  'text-cool-gray group-hover:bg-lavender bg-platinum':
                    theme !== 'dark',
                  'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey':
                    theme === 'dark',
                }
              )}
            >
              {default_model?.name}
            </p>
          </>
        ) : null}

        {files.length > 0 && (
          <div
            className={cn('flex items-center gap-1', {
              'text-agate-violet': theme !== 'dark',
              'text-crystal-bell': theme === 'dark',
            })}
          >
            <PaperClipIcon className={'w-[14px] h-[14px]'} />
            <span className="text-xs leading-[14px] font-medium uppercase">
              {files.length}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const LoadingTemplateCard = () => {
  const { theme } = useThemeStore();
  return (
    <div
      className={cn(
        'flex flex-col gap-2 w-[300px] h-[170px] rounded-xl p-4 shadow-md transition-shadow justify-between px-3 py-[10px] cursor-pointer font-figtree',
        {
          'bg-white  text-[#211E21]': theme !== 'dark',
          'bg-night-black  text-white': theme === 'dark',
        }
      )}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse"></div>
          <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
        </div>
      </div>
      <div className="space-y-2">
        <div className="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse"></div>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
      </div>
    </div>
  );
};

export default TemplateCard;

import {
  ChatBubbleLeftRightIcon,
  PlusIcon,
  SpeakerWaveIcon,
  TrashIcon,
  WrenchIcon,
} from '@heroicons/react/24/solid';
import { cn } from 'utils/styles';

const ToolItem = ({
  onClick,
  isActive = false,
  label,
  name,
  isHidden = false,
  mouseIsOver = false,
}) => {
  if (isHidden) return null;

  const getIcon = (name) => {
    switch (name) {
      case 'New Chat':
        return PlusIcon;
      case 'Select Model':
        return ChatBubbleLeftRightIcon;
      case 'Text To Speech':
        return SpeakerWaveIcon;
      case 'Clear Chat':
        return TrashIcon;
      default:
        return WrenchIcon;
    }
  };

  const Icon = getIcon(label);

  return (
    <>
      <div
        onClick={onClick}
        className={cn(
          `cursor-pointer flex items-center px-2 gap-1 transition-colors`,
          {
            'bg-ghost-white shadow-md': !mouseIsOver && isActive,
            'hover:bg-ghost-white': mouseIsOver,
          }
        )}
        style={{ display: isHidden ? 'none' : 'flex' }}
      >
        <Icon className="w-5 h-5 text-violet-blue p-[1px] font-bold" />
        <li className="py-2 text-sm font-bold font-figtree uppercase">
          {label}
        </li>
      </div>
      <hr className="px-2 bg-cool-gray border-0 h-[2px]" />
    </>
  );
};

export default ToolItem;

/* eslint-disable react/display-name */
import ToolItem from 'Components/Chat/MenuActionsTextField/components/ToolItem';
import useMenuActionStore from 'Components/Chat/MenuActionsTextField/store';
import useTemplateModalStore from 'Components/Chat/TemplateModal/store';
import { useChat } from 'Context/Chat/Chat.context';
import { onNewChat } from 'Features/Core/Chat';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/styles';

const ITEM_HEIGHT = 36;

const MenuActionsTextField = React.forwardRef(
  (
    {
      setShowModelsDialog,
      setShowClearDialog,
      filteredOptions,
      handleScriptForTTS,
    },
    ref
  ) => {
    const {
      promptListRef,
      setSelectedTool,
      toggleShowPromptModal,
      handleShowMobilePrompts,
      activePromptIndex,
      setActivePromptIndex,
    } = useChat();
    const { setIsTemplatePrompt } = useTemplateModalStore();
    const {
      templatesChatList: templates,
      openMenuAction,
      setOpenMenuAction,
    } = useMenuActionStore();

    const navigate = useNavigate();
    const visibleOptions = useMemo(
      () => filteredOptions.filter((option) => !option.isHidden),
      [filteredOptions]
    );

    const handleSelectPrompt = (name) => {
      const selectedToolLocal = templates.find((tool) => tool?.title === name);
      setSelectedTool(selectedToolLocal);
      toggleShowPromptModal();
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('selectedTool', selectedToolLocal?._id);
      navigate(
        {
          pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
          search: urlParams.toString(),
        },
        { shallow: true }
      );
      setOpenMenuAction(false);
      setIsTemplatePrompt(true);
    };

    // -------------------- COMPONENT LOGIC --------------------
    const [mouseIsOver, setMouseIsOver] = useState(false);
    useEffect(() => {
      if (
        promptListRef.current &&
        activePromptIndex != null &&
        activePromptIndex >= 0
      ) {
        const scrollPosition = activePromptIndex * ITEM_HEIGHT;
        promptListRef.current.scrollTop = scrollPosition;
      }
    }, [activePromptIndex, promptListRef]);

    useEffect(() => {
      const handleMouseMove = () => setMouseIsOver(true);
      const handleMouseInactivity = () => setMouseIsOver(false);

      if (promptListRef.current) {
        const promptElement = promptListRef.current;
        promptElement.addEventListener('mousemove', handleMouseMove);
        promptElement.addEventListener('mouseout', handleMouseInactivity);

        return () => {
          promptElement.removeEventListener('mousemove', handleMouseMove);
          promptElement.removeEventListener('mouseout', handleMouseInactivity);
        };
      }
    }, [promptListRef, activePromptIndex]);

    useEffect(() => {
      if (openMenuAction) setActivePromptIndex(-1);
    }, [openMenuAction]);

    // -------------------- END COMPONENT LOGIC --------------------

    const getToolItemFunction = (name) => {
      switch (name) {
        case 'New Chat':
          return onNewChat;
        case 'Select Model':
          return () => setShowModelsDialog(true);
        case 'Text To Speech':
          return () => handleScriptForTTS([]);
        case 'Clear Chat':
          return () => setShowClearDialog(true);
        default:
          return handleShowMobilePrompts;
      }
    };

    const handleClickTemplate = (tool) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleSelectPrompt(tool?.title);
    };

    return (
      <>
        {openMenuAction && (
          <div className="absolute bottom-20 w-full h-56">
            <ul
              ref={ref}
              className={cn(
                'absolute bottom-0 w-96 max-w-full max-h-52 overflow-y-scroll rounded border border-ghost-white bg-lavender text-violet-blue shadow-[0_0_15px_rgba(0,0,0,0.10)]min-w-[50px] my-2 transform transition-all duration-300 ease-in-out',
                openMenuAction
                  ? 'block opacity-100 translate-y-0 scale-100'
                  : 'hidden'
              )}
              style={{ scrollbarWidth: 'none' }}
            >
              <div>
                {visibleOptions.map((option, index) => (
                  <ToolItem
                    key={`${option.name}-${index}`}
                    label={option.name}
                    mouseIsOver={mouseIsOver}
                    isActive={index === activePromptIndex}
                    onClick={getToolItemFunction(option.name)}
                  />
                ))}
              </div>
              {templates.length === 0 && (
                <li className="px-3 py-2 text-base">No tools available</li>
              )}
              {templates.map((tool, index) => {
                const indexCalc = visibleOptions.length - 1 + (index + 1);
                return (
                  <li
                    key={`${tool?.title}-${index}`}
                    onClick={handleClickTemplate(tool)}
                    className={cn(
                      `cursor-pointer px-3 py-2 text-sm transition-colors `,
                      {
                        'bg-ghost-white shadow-md':
                          indexCalc === activePromptIndex,
                        'hover:bg-ghost-white': mouseIsOver,
                      }
                    )}
                  >
                    {tool?.title || tool?.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
);

export default MenuActionsTextField;

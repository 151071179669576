// import necessary hooks
import { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import {
  PlusCircleIcon,
  ChevronDoubleLeftIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  SpeakerWaveIcon,
  XMarkIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import useWindowSize from 'Hooks/useWindowSize';
import { useChat } from 'Context/Chat/Chat.context';
import DragAndDropFolders from '../DragAndDropFolders/DragAndDropFolders';
import { useFoldersChatStore } from '../DragAndDropFolders/store';
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import useThemeStore from 'Theme/store';
import TextSpeechDialog from 'Components/TextSpeechDialog';
import { Button, Tooltip } from '@mui/material';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/solid';
import DeleteChats from './DeleteChats'; // Import the new bulk delete modal
import DeleteChat from '../../../Features/Core/Components/DeleteChat/DeleteChat';
import useFavoriteMessagesStore from '../../Common/Markdown/FavoriteMessages/store';

const ConversationSide = inject('store')(
  observer(
    ({
      show,
      onClick,
      onSelectChat,
      store,
      currentChatId,
      handleScriptForTTS,
      newDesing = false,
    }) => {
      //context
      const { setShowMobileChats, setShowConversationsSide } = useChat();
      const { width } = useWindowSize();
      const [showTextSpeechDialog, setShowTextSpeechDialog] = useState(false);

      useEffect(() => {
        if (width > 1024) {
          setShowMobileChats(false);
        }
      }, [width]);

      const { theme } = useThemeStore();
      const { setModalOpen } = useFoldersChatStore();
      const createFolder = () => {
        setModalOpen(true);
      };

      const {
        showSearch,
        setShowSearch,
        search,
        setSearch,
        setPage,
        selectedChatIds,
        deleteChatsModalOpen,
        setDeleteChatsModalOpen,
        setSelectedChatIds,
        treeData,
        setTreeData,
      } = useTreeDNDChatStore();

      const [deleted, setDeleted] = useState(false); // Local state for deletion status

      const { setOpenFavoriteMessages } = useFavoriteMessagesStore();

      const ScriptForTTS = () => {
        // setScriptValue(value);
        handleScriptForTTS([]);
      };

      const handleConfirmDelete = async () => {
        try {
          const response = await store.api.delete('/chat/many/delete', {
            data: { ids: selectedChatIds },
          });
          if (response.data.success) {
            const updatedTreeData = treeData.filter(
              (node) => !selectedChatIds.includes(node.id)
            );
            setTreeData(updatedTreeData);
            setDeleted(true);
            // Optionally clear selected chat IDs after deletion
            setSelectedChatIds([]);
            setDeleteChatsModalOpen(false);

            // Check if the current chat is among the selected for deletion
            if (selectedChatIds.includes(currentChatId._id)) {
              onClick(); // Open a new chat if current chat is deleted
            }
          }
        } catch (err) {
          console.error('Failed to delete chats:', err);
        }
      };

      const toggleFavoriteMessages = () => {
        setOpenFavoriteMessages(true);
      };

      // TODO: JS remove new desing var
      return (
        <>
          {!newDesing ? (
            <div
              className={`z-10 dark:from-lead dark:to-night-black bg-gradient-to-b h-full from-lavender font-figtree to-seasalt lg:bg-none lg:bg-seasalt dark:bg-lead relative lg:px-4 ${
                show ? '' : 'hidden'
              }`}
            >
              {deleteChatsModalOpen && selectedChatIds.length > 1 && (
                <DeleteChats
                  onClose={() => setDeleteChatsModalOpen(false)}
                  onDelete={handleConfirmDelete}
                  deleted={deleted} // Pass deleted as prop
                  setDeleted={setDeleted} // Pass setDeleted as prop
                />
              )}
              {deleteChatsModalOpen && selectedChatIds.length === 1 && (
                <DeleteChat
                  onClose={() => setDeleteChatsModalOpen(false)}
                  onDelete={handleConfirmDelete}
                  deleted={deleted} // Pass deleted as prop
                  setDeleted={setDeleted} // Pass setDeleted as prop
                />
              )}
              <div className="hidden lg:flex justify-between items-center pt-2">
                <p className="text-raisin-black dark:text-crystal-bell uppercase font-bold">
                  ai chat assistant
                </p>
                <div
                  onClick={() => {
                    setShowConversationsSide(false);
                  }}
                  className="hidden lg:flex w-[20px] cursor-pointer h-[40px] bg-ghost-white dark:bg-night-black rounded items-center justify-center"
                >
                  <ChevronDoubleLeftIcon className="h-6 w-6 text-raisin-black dark:text-crystal-bell" />
                </div>
              </div>
              <div className="hidden justify-between items-center lg:flex mt-3 bg-lavender dark:bg-ship-grey px-[10px] py-[6px] rounded">
                <p className="text-violet-blue dark:text-tropical-indigo uppercase font-bold text-sm">
                  My chats
                </p>
              </div>
              <div className="hidden lg:flex gap-2 items-center mt-3">
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-md"
                  onClick={onClick}
                >
                  <PlusCircleIcon className={'h-6 w-6 text-white'} />
                </div>
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md dark:bg-ship-grey dark:hover:bg-quicksilver"
                  onClick={createFolder}
                >
                  <FolderPlusIcon
                    className={
                      'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md dark:bg-ship-grey dark:hover:bg-quicksilver"
                  onClick={ScriptForTTS}
                >
                  <SpeakerWaveIcon
                    className={
                      'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md dark:bg-ship-grey dark:hover:bg-quicksilver"
                  onClick={toggleFavoriteMessages}
                >
                  <StarIcon
                    className={
                      'h-6 w-6 text-graphite-800 dark:text-celadon-900'
                    }
                  />
                </div>
                {/* <div
                  onClick={() => setShowSearch(!showSearch)}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md dark:bg-ship-grey dark:hover:bg-quicksilver"
                >
                  <MagnifyingGlassIcon
                    className={
                      'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div> */}
                {/* {showSearch && (
                  <div className="flex w-[calc(100%-9rem)] relative">
                    <input
                      autoFocus
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }}
                      type="text"
                      placeholder="Search"
                      className={`${
                        theme == 'dark'
                          ? 'dark:bg-night-black dark:text-crystal-bell'
                          : 'bg-white text-raisin-black'
                      }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray`}
                      autoComplete="off"
                    />
                    {search && (
                      <div
                        onClick={() => setSearch('')}
                        className="flex items-center justify-center w-[32px] h-[32px] bg-ghost-white hover:bg-cool-gray cursor-pointer rounded-md dark:bg-palladium dark:hover:bg-quicksilver absolute right-0 top-0 bottom-0"
                      >
                        <XMarkIcon
                          className={
                            'h-6 w-6 text-raisin-black dark:text-crystal-bell'
                          }
                        />
                      </div>
                    )}
                  </div>
                )} */}
                <div>
                  {selectedChatIds.length > 0 && (
                    <Tooltip
                      title={`Delete Selected Chat${
                        selectedChatIds.length > 1 ? 's' : ''
                      }`}
                      placement="right"
                      arrow
                    >
                      <Button
                        sx={{
                          textTransform: 'capitalize',
                          fontWeight: 'normal',
                        }}
                        onClick={() => setDeleteChatsModalOpen(true)}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        <TrashIcon className="w-6 h-6" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div
                className="hidden md:block h-[2px] bg-cool-gray my-2"
                content=" "
              />
              <div className="font-bold uppercase py-4 text-raisin-black dark:text-crystal-bell text-2xl pl-4 lg:hidden">
                My chatList
              </div>
              <div className="px-4 flex gap-2 lg:hidden">
                <div
                  onClick={() => toggleFavoriteMessages()}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md"
                >
                  <StarIcon className={'h-6 w-6 text-graphite-800'} />
                </div>
                <div
                  onClick={() => setShowSearch(!showSearch)}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md"
                >
                  <MagnifyingGlassIcon className={'h-6 w-6 text-violet-blue'} />
                </div>
                {/* <div className="flex w-full">
                  <input
                    autoFocus
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    type="text"
                    placeholder="Search"
                    className={`appearance-none ${
                      theme == 'dark'
                        ? 'dark:bg-night-black dark:text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray`}
                    autoComplete="on"
                  />
                  {search && (
                    <div
                      onClick={() => setSearch('')}
                      className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-md dark:bg-ship-grey dark:hover:bg-quicksilver absolute right-0 top-0 bottom-0"
                    >
                      <XMarkIcon
                        className={
                          'h-6 w-6 text-violet-blue dark:text-tropical-indigo'
                        }
                      />
                    </div>
                  )}
                </div> */}
                <div>
                  {selectedChatIds.length > 0 && (
                    <Tooltip
                      title={`Delete Selected Chat${
                        selectedChatIds.length > 1 ? 's' : ''
                      }`}
                      placement="right"
                      arrow
                    >
                      <Button
                        sx={{
                          textTransform: 'capitalize',
                          fontWeight: 'normal',
                        }}
                        onClick={() => setDeleteChatsModalOpen(true)}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        <TrashIcon className="w-6 h-6" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <>
                <div className="supports-[height:100cqh]:h-[calc(100cqh-20em)] supports-[height:100svh]:h-[calc(100cqh-20em)] lg:h-[calc(100vh-14em)] w-full">
                  <div className="px-4 md:px-0 py-2 lg:py-0 rounded-l-md rounded-br-md h-[100%] w-full">
                    <DragAndDropFolders onSelectChat={onSelectChat} />
                  </div>
                </div>
              </>
              {showTextSpeechDialog && (
                <TextSpeechDialog
                  onClose={() => setShowTextSpeechDialog(false)}
                  script={''}
                />
              )}
            </div>
          ) : (
            <>
              <div className="flex gap-2 items-center px-4 pb-2 pb-2">
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver"
                  onClick={createFolder}
                >
                  <FolderPlusIcon
                    className={
                      'h-5 w-5 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                <div
                  onClick={() => toggleFavoriteMessages()}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-lavender hover:bg-ghost-white cursor-pointer rounded-[10px] dark:bg-ship-grey dark:hover:bg-quicksilver"
                >
                  <StarIcon
                    className={
                      'h-5 w-5 text-violet-blue dark:text-tropical-indigo'
                    }
                  />
                </div>
                <div
                  className={`flex flex-1 items-center relative rounded-[10px] transition ${
                    theme === 'dark'
                      ? 'focus-within:border-[#787878]'
                      : 'focus-within:border-[#CFD0D6]'
                  } border-2 border-transparent rounded-[12px]`}
                >
                  <input
                    autoFocus
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    type="text"
                    placeholder="Search"
                    className={`${
                      theme === 'dark'
                        ? 'dark:bg-night-black dark:text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    } h-8 text-sm focus:outline-none transition w-full pl-8 p-1 rounded-[10px] placeholder-battleship-gray`}
                    autoComplete="off"
                  />
                  <span
                    className={`${
                      theme === 'dark'
                        ? 'dark:text-[#787878]'
                        : 'text-[#51526C]'
                    } absolute left-2.5`}
                  >
                    <MagnifyingGlassIcon className={'h-4 w-4'} />
                  </span>
                  {search && (
                    <div
                      onClick={() => setSearch('')}
                      className="my-[2px] flex items-center justify-center w-7 h-7 hover:bg-cool-gray cursor-pointer rounded-[10px] dark:hover:bg-quicksilver absolute right-0 top-0 bottom-0"
                    >
                      <XMarkIcon
                        className={
                          'h-4 w-4 text-raisin-black dark:text-crystal-bell'
                        }
                      />
                    </div>
                  )}
                </div>

                {selectedChatIds.length > 0 && (
                  <Tooltip
                    title={`Delete Selected Chat${
                      selectedChatIds.length > 1 ? 's' : ''
                    }`}
                    placement="right"
                    arrow
                  >
                    <Button
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 'normal',
                        borderRadius: '10px',
                        height: '32px',
                        borderRadius: '10px',
                        height: '32px',
                      }}
                      onClick={() => setDeleteChatsModalOpen(true)}
                      size="small"
                      variant="contained"
                      color="primary"
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <DragAndDropFolders onSelectChat={onSelectChat} />

              {/* Dialogs */}
              {deleteChatsModalOpen && selectedChatIds.length > 1 && (
                <DeleteChats
                  onClose={() => setDeleteChatsModalOpen(false)}
                  onDelete={handleConfirmDelete}
                  deleted={deleted} // Pass deleted as prop
                  setDeleted={setDeleted} // Pass setDeleted as prop
                />
              )}
              {deleteChatsModalOpen && selectedChatIds.length === 1 && (
                <DeleteChat
                  onClose={() => setDeleteChatsModalOpen(false)}
                  onDelete={handleConfirmDelete}
                  deleted={deleted} // Pass deleted as prop
                  setDeleted={setDeleted} // Pass setDeleted as prop
                />
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default ConversationSide;

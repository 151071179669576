import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

function Oauth({ store }) {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const automaticLogin = async () => {
    try {
      let data = await store.api
        .post('/auth/signin-oauth', {
          token: token,
        })
        .then(({ data }) => data);
      store.loginWithDataTokenAndProfile(data);
      if (data.fpr != null) {
        console.log('1PR oauth-----');
        console.log(data.profile.email);
        console.log(data.profile.customerId);
        console.log(data.profile.reference_1pr);
        console.log('--------');
        window.fpr('referral', {
          email: data.profile.email,
          uid: data.profile.customerId,
        });
      }
      store.sendMetricsEvent('login', null, { method: 'signin-oauth' });
      navigate('/');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      // eslint-disable-next-line no-console
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        setError(true);
        setErrorMessage(err?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    automaticLogin();
  }, [navigate, token]);

  return (
    <>
      {error ? (
        <main className="h-screen w-full flex flex-col justify-center items-center font-figtree">
          <div className="relative">
            <h1 className="text-5xl md:text-9xl font-extrabold text-center text-raisin-black tracking-widest">
              Invalid token
            </h1>
            <div className="w-full px-2 text-xl text-center text-cool-gray mt-2">
              We apologize for the inconvenience, but the token you provided is
              invalid. Please make sure to use a valid token to access our
              services.
            </div>
          </div>
          <NavLink to="/login">
            <button className="mt-5">
              <a className="relative inline-block text-sm font-medium text-nue-blue group active:text-violet-blue focus:outline-none focus:ring">
                <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-nue-blue group-hover:translate-y-0 group-hover:translate-x-0"></span>

                <span className="relative block px-8 py-3 bg-nue-blue text-white border border-current border-lavender">
                  Go to Login
                </span>
              </a>
            </button>
          </NavLink>
        </main>
      ) : null}
    </>
  );
}

export default inject('store')(observer(Oauth));

/* eslint-disable no-constant-condition */
import { useEffect, useMemo, useState } from 'react';
// react-router-dom
import { useLocation } from 'react-router-dom';
// Mobx
import { inject, observer } from 'mobx-react';
// Moment
import moment from 'moment';
// Store
import usePublicLayoutStore from 'Layouts/PublicLayout/State';
// MUI
import { Box, useMediaQuery } from '@mui/material';
// Icons
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import PDFFileUpload from 'Theme/icons/pdfFileUpload';
import UrlFileUpload from 'Theme/icons/urlFileUpload';
// Components
import ContentMessage from 'Components/Common/Markdown/ContentMessage';
import ImageDialog from 'Components/ImageDialog';
import Loader from 'Components/Loader';
import useDisableIOSZoom from 'Hooks/useDisableIOSZoom';
import ModalRegister from 'Layouts/PublicLayout/Components/ModalRegister';
import ModelsIcon from 'Theme/icons/modelsIcon';
import { icons as modelIcons } from 'Theme/icons/modelsIcons/index';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import styled from 'styled-components';

const ShareChatPage = inject('store')(
  observer(({ store }) => {
    useDisableIOSZoom();
    const router = useLocation();
    // State
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    // Capabilities variables
    const [capabilities, setCapabilities] = useState(
      JSON.parse(localStorage.getItem('capabilities')) || []
    );

    const [modelsList, setModelsList] = useState([]);

    // Get share chat
    const onGetShare = async (id) => {
      setLoading(true);
      try {
        const res = await store.api.get(`/shareChats/${id}`);
        const data = await res.data;
        setData(data);
      } catch (err) {
        setError(true);
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      getModels();
    }, []);

    useEffect(() => {
      if (router.pathname.split('/').pop()) {
        onGetShare(router.pathname.split('/').pop());
      }
    }, []);

    useEffect(() => {
      (async () => {
        await getCapabilities();
      })();
    }, []);

    const getModels = async () => {
      try {
        const response = await store.api.get(`/model`);
        setModelsList(response.data.models);
      } catch (error) {
        console.error(error);
      }
    };

    const getCapabilities = async () => {
      try {
        const response = await store.api.get(`/capability`);
        setCapabilities(response.data.capabilities);
      } catch (error) {
        console.error(error);
      }
    };

    // Format date
    const formatDate = useMemo(
      () => moment(data?.created).format('MMMM D, YYYY'),
      [data]
    );

    // Global state management
    const {
      registerModal,
      closeRegisterModal,
      showPromptSidebar,
      togglePromptSidebar,
      openRegisterModal,
      toggleContinueConversation,
    } = usePublicLayoutStore();

    // media querys
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Continue this conversation
    const setRedirectUrl = () => {
      sessionStorage.setItem('redirectUrl', `/chat?chat=${data?.chat?._id}`);
    };
    const continueConversation = () => {
      openRegisterModal();
      toggleContinueConversation();
    };

    const getTabs = (message) => {
      return Array.isArray(message) ? message : [message];
    };

    const isSelected = (item, ele) => {
      if (!Array.isArray(item) || (ele._id && ele.selected == true)) {
        return true;
      }

      return false;
    };

    const handleOutput = async (item, element, index, ind) => {
      if (!Array.isArray(item)) {
        return;
      }

      let copy = JSON.parse(JSON.stringify(data));
      if (element._id) {
        for (let i = 0; i < item.length; i++) {
          // console.log(data.chat.messages[ind][i]);
          copy.chat.messages[ind][i].selected = i == index ? true : false;
        }
        setData(copy);
      }
    };

    const getCapabilityState = (func) => {
      const capability = capabilities.find((cap) => cap._id == func.capability);
      return (
        capability && (
          <div className="flex rounded py-1 px-2 my-2 gap-2 bg-lavender text-battleship-gray w-fit text-xs items-center">
            {getIcon(capability.icon, 'h-4 w-4')}
            <span>{capability.label_states[func.state]}</span>
          </div>
        )
      );
    };

    const getIcon = (icon, className = 'h-6 w-6') => {
      const Icon = get(outlineIcons, icon);
      return Icon ? <Icon className={className} /> : null;
    };

    const getLabelMinutes = (seconds) => {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;
      let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
      let secondsStr =
        remainingSeconds < 10
          ? '0' + remainingSeconds
          : remainingSeconds.toString();
      return minutesStr + ':' + secondsStr;
    };

    const checkCapability = (ele) => {
      if (!ele.data.function) {
        return;
      }

      const capability = capabilities.find(
        (cap) => cap._id == ele.data.function.capability
      );

      return (
        capability && (
          <Shortcut>
            {getIcon(capability.icon, 'h-4 h-4')}
            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex left-[calc(100%_-_0.5rem)] top-1/2">
              <div className="p-2 text-sm leading-none text-cool-gray bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                Capability: <span className="font-bold">{capability.name}</span>
              </div>
            </Tooltip>
          </Shortcut>
        )
      );
    };

    const isHidden = (item) => {
      return Array.isArray(item) ? item.some((ele) => ele.hidden) : item.hidden;
    };

    const isUser = (item) => {
      return !Array.isArray(item) && item.data.role === 'user';
    };

    const getModelIcon = (item) => {
      let currentModel;
      if (!Array.isArray(item)) {
        currentModel = item;
      } else {
        currentModel = item.find((e) => e.selected);
      }

      const icon = currentModel
        ? modelsList.find((m) => m._id == currentModel.modelRef)
        : null;
      const Icon = icon && icon.icon ? get(modelIcons, icon.icon) : null;
      return Icon ? (
        <Icon className="h-9 w-9" />
      ) : (
        <ModelsIcon className="h-9 w-9 stroke-cool-gray" />
      );
    };

    if (error)
      return (
        <div className="flex-1 h-[calc(100vh_-_48px)] bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell w-full flex justify-center items-center font-figtree">
          <div className="text-center">
            <h1 className="text-3xl font-bold">
              Oops, Trouble Accessing Shared Content!
            </h1>
            <p className="text-xl">
              This shared chat seems to be currently unavailable. For the best
              experience, we recommend reaching out to the original creator to
              request a new share link.
            </p>
          </div>
        </div>
      );

    return (
      <>
        {loading ? (
          <div className="flex-1 w-full flex justify-center items-center bg-seasalt dark:bg-lead">
            <Loader />
          </div>
        ) : (
          <div className="flex-1 w-full bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell flex flex-col font-figtree">
            <div className="relative flex-1 flex flex-col">
              <div className="flex-1 flex flex-col relative">
                <div className="flex-1 relative w-full h-full flex flex-col overflow-auto pb-[4rem] items-center">
                  <div className="w-full lg:max-w-5xl">
                    <div className="w-full px-16">
                      {/* Creator data */}
                      <div className="flex items-center space-x-4 mb-6">
                        <div className="w-12 h-12 rounded-full overflow-hidden">
                          {data?.chat?.creator.avatar.svg ? (
                            <div className="avatar">
                              <div className="w-12 h-12 rounded-full overflow-hidden">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data?.chat?.creator.avatar.svg,
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <UserCircleIcon className="w-12 h-12 transition text-raisin-black dark:text-crystal-bell" />
                          )}
                        </div>
                        <div className="text-[28px] leading-[34px] font-bold">{`${data?.chat?.creator.fname} ${data?.chat?.creator.lname}`}</div>
                      </div>

                      {/* Chat data */}
                      <div className="flex flex-col gap-3">
                        <div className="text-sm leading-5 font-bold text-agate-violet dark:text-crystal-bell">
                          Chat
                        </div>
                        <div className="text-xl leading-[30px] font-bold line-clamp-1 text-nue-blue dark:text-stargate-shimmer">
                          {data?.title}
                        </div>
                        <div className="flex gap-2">
                          {data?.modelList.map((model, index) => (
                            <div
                              className="bg-platinum dark:bg-night-black text-cool-gray dark:text-battleship-gray rounded text-xs leading-4 font-bold uppercase px-[6px]"
                              key={index}
                            >
                              {model}
                            </div>
                          ))}
                        </div>
                        <div className="flex gap-2 text-agate-violet dark:text-crystal-bell text-xs leading-4">
                          <CalendarDaysIcon className="w-4 h-4" />
                          {formatDate}
                        </div>
                      </div>
                      <div>
                        {data?.chat?.files?.length > 0 && (
                          <>
                            <div className="px-3 py-2 mt-2 w-[calc(100%_-_1.5rem)] z-10 rounded-xl font-figtree bg-lavender dark:bg-ship-grey text-violet-blue dark:text-tropical-indigo">
                              <h3 className="text-sm">
                                {`Attached file${
                                  data?.chat?.files.length > 1 ? 's' : ''
                                }`}
                              </h3>
                              {data?.chat?.files &&
                                data?.chat?.files.map((file, index) => {
                                  return (
                                    <div
                                      className="pt-3 flex gap-2 items-center"
                                      key={index}
                                    >
                                      {file.metadata?.type == 'youtube' ? (
                                        <img
                                          src={file.metadata?.thumbnail}
                                          className="w-16 h-10 mt-1"
                                        />
                                      ) : file.metadata?.type == 'pdf' ? (
                                        <PDFFileUpload />
                                      ) : (
                                        <UrlFileUpload />
                                      )}
                                      <div className="ml-2 flex-grow">
                                        <a
                                          className="font-medium text-sm"
                                          target="_blank"
                                          href={file.metadata?.url}
                                          rel="noreferrer"
                                        >
                                          {file.metadata?.name}
                                        </a>
                                        {file.metadata?.type == 'youtube' ? (
                                          <p className="text-xs font-regular">
                                            {getLabelMinutes(
                                              file?.metadata?.size
                                            )}{' '}
                                            | {file?.metadata?.channel}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Chat render */}
                    {data?.chat?.messages.map(
                      (item, ind) =>
                        !isHidden(item) &&
                        getTabs(item).map(
                          (message, index) =>
                            isSelected(item, message) && (
                              <div
                                key={message._id ? message._id : 'user' + index}
                              >
                                <div
                                  className={`w-full text-token-text-primary border-b border-black/10 dark:border-white/10 gizmo:border-0`}
                                >
                                  <div className="p-4 justify-center text-base m-auto">
                                    <div className="flex flex-1 gap-4 text-base mx-auto">
                                      <div>
                                        {/* AI Robot */}
                                        {!isUser(item) && (
                                          <div className="h-min relative group flex flex-col items-center group">
                                            <div className="avatar">
                                              <div className="w-9">
                                                <div className="w-full h-full flex items-center justify-center">
                                                  {getModelIcon(item)}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {/* Person - avatar */}
                                        {isUser(item) && (
                                          <div className="h-min relative group flex flex-col items-center group">
                                            <UserCircleIcon className="w-9 h-9 text-raisin-black dark:text-crystal-bell" />
                                          </div>
                                        )}
                                      </div>
                                      <div className="flex-1 w-[calc(100%_-_52px)]">
                                        <div className="relative flex flex-1 flex-wrap gap-2">
                                          {message.data.role !== 'user' &&
                                            getTabs(item).map((ele, idx) => (
                                              <p
                                                key={idx}
                                                className={`w-fit font-semibold rounded-lg px-1 text-[11px] cursor-pointer select-none ${
                                                  isSelected(item, ele)
                                                    ? 'bg-nue-blue text-white dark:text-crystal-bell'
                                                    : 'bg-platinum text-cool-gray dark:bg-ship-grey dark:text-tropical-indigo'
                                                } flex gap-1 items-center`}
                                                onClick={() =>
                                                  isSelected(item, ele)
                                                    ? null
                                                    : handleOutput(
                                                        item,
                                                        ele,
                                                        idx,
                                                        ind
                                                      )
                                                }
                                              >
                                                <span className="uppercase">
                                                  {ele.model}
                                                </span>
                                                {checkCapability(ele)}
                                              </p>
                                            ))}
                                        </div>
                                        <div className="empty:hidden gizmo:empty:block text-raisin-black dark:text-crystal-bell">
                                          {getTabs(item).map(
                                            (ele, idx) =>
                                              isSelected(item, ele) &&
                                              ele?.data?.content && (
                                                <>
                                                  {ele.data.function &&
                                                    getCapabilityState(
                                                      ele.data.function
                                                    )}
                                                  <ContentMessage
                                                    key={idx}
                                                    message={ele.data.content}
                                                    userType={ele.data.role}
                                                    onOpenFull={(content) => {
                                                      setDialogContent(content);
                                                      setShowImageDialog(true);
                                                    }}
                                                  />
                                                </>
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                    )}
                  </div>
                </div>
                <div className="flex flex-col fixed bottom-0 w-full">
                  <Box
                    sx={{
                      animation: 'jump 3s infinite ease-in-out',
                      '@keyframes jump': {
                        '0%, 20%, 50%, 80%, 100%': {
                          transform: 'translateY(0)',
                        },
                        '40%': {
                          transform: 'translateY(-15px)',
                        },
                        '60%': {
                          transform: 'translateY(-15px)',
                        },
                      },
                    }}
                    className={`absolute top-[-80px] left-0 right-0 mx-auto mb-12 w-[${
                      showPromptSidebar ? (isMobile ? 100 : 70) : 100
                    }%] md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient  !bg-transparent dark:md:bg-vert-dark-gradient pt-2 md:pl-2`}
                  >
                    <div className="relative flex h-full w-full flex-1 items-center justify-center gap-2">
                      <div
                        onClick={continueConversation}
                        className="flex items-center justify-center px-4 py-2 text-lg font-bold text-white bg-nue-blue rounded-xl hover:bg-violet-blue cursor-pointer select-none"
                      >
                        <div className="flex w-full gap-2 items-center justify-center">
                          Sign up to chat
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <ModalRegister
              isLoggedIn={store.isLoggedIn}
              open={registerModal}
              onClose={closeRegisterModal}
              setRedirectUrl={setRedirectUrl}
              url={`/chat?chat=${data?.chat?._id}`}
            />
          </div>
        )}
        {showImageDialog && (
          <ImageDialog onClose={() => setShowImageDialog(false)}>
            {dialogContent}
          </ImageDialog>
        )}
      </>
    );
  })
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

export default ShareChatPage;

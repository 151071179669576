import {
  BoltIcon,
  PaperClipIcon,
  RocketLaunchIcon,
  SparklesIcon,
  TagIcon,
} from '@heroicons/react/24/solid';
import Tooltip from '@mui/material/Tooltip';
import { useChat } from 'Context/Chat/Chat.context';
import Emoji from 'react-emojis';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';

const TemplateFav = ({ tool, isSmallMobile }) => {
  // TODO cuando hace onClick tiene que abir el tool
  const navigate = useNavigate();

  const { theme: themeValue } = useThemeStore();

  const { setSelectedTool, toggleShowPromptModal, setShowMobilePrompts } =
    useChat();

  const handleSelectPrompt = () => {
    const selectedToolLocal = tool;
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
  };

  const onClickTemplate = (e) => {
    setShowMobilePrompts(false);
    handleSelectPrompt();
    // url params
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const veriftyEmoji = (tool) => {
    let iconTag = 'high-voltage';
    if (tool.Icon) {
      if (tool.Icon !== ' ') {
        iconTag = tool.Icon;
      }
    }
    return <Emoji emoji={iconTag} />;
  };

  const imageGenerationTools = [
    '65805947d92e370a3d5625c6',
    '66c786d7e26442bc593e575d',
  ];

  const setImageToolNames = (tool) => {
    if (tool?.id == '65805947d92e370a3d5625c6') {
      return 'OpenAi: Dall-E 3';
    } else if (tool?.id == '66c786d7e26442bc593e575d') {
      return 'Fal-Ai: FLUX.1 [pro]';
    }
    return '';
  };

  return (
    <div
      className={`${
        themeValue == 'dark' ? 'bg-night-black' : 'bg-white'
      } font-figtree rounded-[14px] w-[260px] p-3 my-3 cursor-pointer flex flex-col gap-3 shadow-[4px_4px_7.6px_0px_rgba(0,0,0,0.10)]`}
      onClick={onClickTemplate}
    >
      <div
        className={`flex items-center ${
          themeValue == 'dark' ? 'text-palladium' : 'text-cool-gray'
        }`}
      >
        <TagIcon className={'h-4 w-4 mr-1'} />
        <h2 className="text-xs leading-4 font-semibold">{tool.category}</h2>
        <div className="flex-1"></div>
        <div className="flex gap-[6px] items-center text-agate-violet">
          {tool.userInfo?.avatar.svg ? (
            <div className="avatar">
              <div className="w-4 h-4 rounded">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tool.userInfo?.avatar.svg,
                  }}
                />
              </div>
            </div>
          ) : (
            <BoltIcon className="w-4 h-4 transition rounded-full text-white bg-nue-blue" />
          )}

          {tool.runs ? (
            <div className="flex gap-1 items-center">
              <RocketLaunchIcon
                className={`w-4 h-4 ${
                  themeValue == 'dark' ? 'text-palladium' : 'text-cool-gray'
                }`}
              />
              <div
                className={`text-xs font-medium leading-4 ${
                  themeValue == 'dark' ? 'text-palladium' : 'text-cool-gray'
                }`}
              >
                {Intl.NumberFormat('en-US').format(tool.runs)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xl">{veriftyEmoji(tool)}</div>
        <h2
          className={`text-sm font-semibold leading-[14px] text-left ${
            themeValue == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          {tool.title}
        </h2>
      </div>
      <div
        className={`${
          themeValue == 'dark' ? 'text-crystal-bell' : 'text-agate-violet'
        } text-xs leading-[18px] font-normal line-clamp-2`}
      >
        {tool.desc}
      </div>
      <div className="flex justify-between">
        {tool?.multi_default_models?.length > 1 ? (
          <div className={'flex items-center'}>
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: tool.multi_default_models
                      .map((model) => model.name)
                      .join('<br>'),
                  }}
                />
              }
              arrow
            >
              <p
                className={`flex items-center uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit ${
                  themeValue == 'dark'
                    ? 'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey'
                    : 'text-cool-gray group-hover:bg-lavender bg-platinum'
                }`}
              >
                {tool?.multi_default_models[0]?.name} +{' '}
                {tool?.multi_default_models?.length - 1}
                <SparklesIcon className="w-[10px] h-[10px] ml-1" />
              </p>
            </Tooltip>
          </div>
        ) : tool?.multi_default_models?.length == 1 ? (
          <>
            <p
              className={`uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit ${
                themeValue == 'dark'
                  ? 'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey'
                  : 'text-cool-gray group-hover:bg-lavender bg-platinum'
              }`}
            >
              {tool?.multi_default_models[0]?.name}
            </p>
          </>
        ) : tool?.default_model ? (
          <>
            <p
              className={`uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit ${
                themeValue == 'dark'
                  ? 'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey'
                  : 'text-cool-gray group-hover:bg-lavender bg-platinum'
              }`}
            >
              {tool?.default_model?.name}
            </p>
          </>
        ) : imageGenerationTools.includes(tool?._id) ? (
          <p
            className={`uppercase px-[6px] rounded text-[10px] leading-[14px] font-bold w-fit ${
              themeValue == 'dark'
                ? 'bg-sonic-silver text-crystal-bell group-hover:text-ship-grey'
                : 'text-cool-gray group-hover:bg-lavender bg-platinum'
            }`}
          >
            {setImageToolNames(tool)}
          </p>
        ) : null}

        {tool.files.length > 0 && (
          <div
            className={`flex items-center gap-1 ${
              themeValue == 'dark' ? 'text-crystal-bell' : 'text-agate-violet'
            }`}
          >
            <PaperClipIcon className={'w-[14px] h-[14px]'} />
            <span className="text-xs leading-[14px] font-medium uppercase">
              {tool.files.length}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateFav;

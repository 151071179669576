/* eslint-disable no-console */
import useMenuActionStore from 'Components/Chat/MenuActionsTextField/store';
import { useCallback, useEffect } from 'react';

const useMenuActionsTextFieldLogic = (
  store,
  debouncedValueText,
  promptListRef,
  renderType,
  setRenderType,
  setShowPromptList,
  TABS,
  ROWS,
  isMobile,
  setShowMobileChats
) => {
  const { setTemplatesChatList: setTemplates } = useMenuActionStore();

  /**
   * Update prompt chatList visibility when user type a command or text
   */
  useEffect(() => {
    updateTemplateList(debouncedValueText);
  }, [debouncedValueText]);

  // First render load
  useEffect(() => {
    updateTemplateList('');
  }, []);

  /**
   * Get tools and filter by keyword
   * @param {string} text
   */
  const searchToolsByKeyword = useCallback(async (text) => {
    try {
      const response = await store.api.get('/tool', {
        params: { keyword: text.replace('/', '') },
      });

      const filterData = response.data.tools
        ?.filter((tool) =>
          tool.permissions.some((r) => store.profile.permissions.includes(r))
        )
        ?.filter((tool) => tool.category !== 'Media')
        .filter((tool) =>
          tool.title?.toLowerCase().includes(text.replace('/', ''))
        )
        .sort((a, b) => a.title.localeCompare(b.title));

      setTemplates(filterData ?? []);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('API error:', error);
    }
  }, []);

  const updateTemplateList = useCallback(
    async (text) => {
      const match = text.match(/^\/.*/);

      if (match) {
        searchToolsByKeyword(text);
        if (isMobile) {
          setShowMobileChats(false);
        }
      }
      setShowPromptList(match);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setShowPromptList]
  );

  /**
   * IMPORTANT to close when user click outside
   */
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        promptListRef?.current &&
        !promptListRef?.current.contains(e.target)
      ) {
        setShowPromptList(false);
      }
    };

    const handleResize = () => {
      if (renderType !== TABS && window.innerWidth < 768) {
        setRenderType(ROWS);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMenuActionsTextFieldLogic;
